<template>
    <div class="phi-tabs">

        <vue-scroll :ops="scrollerOptions" v-if="type == 'scrollable'">
            <slot></slot>
        </vue-scroll>

        <div class="fixed-slot" v-else>
            <slot></slot>
        </div>

    </div>
</template>

<script>
import Vue from 'vue';
import vuescroll from 'vuescroll';
import 'vuescroll/dist/vuescroll.css';
Vue.use(vuescroll);

export default {
    name: "phi-tabs",

    components: {},

    data () {
        return {
            selectedTab: null,
            scrollerOptions: {
                mode: "native",
                scroller: {
                    bouncing: false
                },
                scrollPanel: {
                    scrollingY: false,
                    padding: true
                },
                rail: {
                    size: "7px",
                    opacity: 0
                },
                bar: {
                    background: "#8E8E8E",
                    keepShow: false,
                    opacity: 0.7
                }
            }
        }
    },

    model: {
        prop: 'value',
        event: 'change'
    },

    props: {

        type: {
            type: String,
            required: false,
            default: "scrollable",
            validator: value => ["fixed", "scrollable"].indexOf(value) >= 0
        },

        value: {
            required: false,
            default: null
        },
        
        allowReSelection: {
            type: Boolean,
            required: false,
            default: false
        }

    },

    methods: {
        tabSelected(tabId, tabEl){
            this.$emit("change", tabId);

            if(!this.isTouchDevice()){
                if(this.type == "scrollable"){
                    let centerScroll = true;

                    if (typeof device != "undefined") {
                        if (device.platform == "iOS") {
                           centerScroll = false;
                        }
                    }

                    if(centerScroll){
                        tabEl.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
                    }else{
                        tabEl.scrollIntoView();
                    }
                }
            }
        },

        isTouchDevice(){
            return ('ontouchstart' in window || navigator.msMaxTouchPoints > 0);
        }
    },

    mounted () {
        this.$on("tabClicked", (id, el) => {this.tabSelected(id, el)})

        if(this.isTouchDevice()){
            this.scrollerOptions.mode = 'slide';
            this.scrollerOptions.bar.opacity = 0;
            this.scrollerOptions.scrollPanel.padding = false;
        }else{
            this.scrollerOptions.bar.keepShow = true;
        }
    }
}
</script>

<style lang="scss">
.phi-tabs{
    --offset-width: '0px';
    --element-height: '48px';

    width: 100%;
    height: var(--element-height);
    white-space: nowrap;

    ::-webkit-scrollbar {
        display: none;
    }

    .offset{
        width: var(--offset-width);
        height: 1px;
        display: inline-block;
        box-sizing: border-box;
        position: relative;
        background-color: transparent;
    }

    .fixed-slot{
        width: 100%;
        height: var(--element-height) !important;

        display: flex;
    }

    div.__rail-is-horizontal{
        bottom: unset;
        top: 2px;
    }
}
</style>
