<template>
    <div class="phi-tab" :class="{ flexible: isFixedTab }">

        <div
            class="phi-tab-item"
            :class="{ selected: isSelectedTab }"
            @click="selectTab"
        >
            <phi-icon :value="gIcon" size="24" v-if="icon"></phi-icon>
            <label v-if="label" :badge="badgeContent">{{label}}</label>
        </div>

    </div>
</template>

<script>
import PhiIcon from '@/components/Phi/Icon/Icon.vue';

export default {
    name: "phi-tab",
    components: {PhiIcon},

    data () {
        return {
            tabsParent: null
        }
    },

    props: {

        value: {
            required: true,
            default: null
        },

        label: {
            type: String,
            required: false
        },

        icon: {
            type: String,
            required: false
        },

        badgeContent: {
            required: false,
            default: ""
        }
    },

    computed: {
        isSelectedTab: function () {
            if(this.tabsParent !== null){
                return this.value === this.tabsParent.value;
            }else{
                return false;
            }
        },

        isFixedTab: function () {
            if(this.tabsParent !== null){
                return this.tabsParent.type === "fixed";
            }else{
                return false;
            }
        },
        
        gIcon: function () {
            return "g:" + this.icon;
        }
    },

    methods: {
        initialize() {
            let parent = this.$parent;
            while(!parent.$el.classList.contains("phi-tabs")){
                parent = parent.$parent;
            }
            this.tabsParent = parent;

            if(this.icon){
                this.$el.style.setProperty('--element-height', "72px");
            }
        },

        selectTab(){
            let select = true;
            if(!this.tabsParent.allowReSelection && this.isSelectedTab){
                select = false;
            }
            
            let tabIndex = 0;
            let currentIndex = 0;
            let newIndex = 0;
            
            this.tabsParent.$slots.default.forEach((vnode) => {
                if(vnode.componentInstance){
                    tabIndex += 1;
                    if(vnode.elm.__vue__.value == this.value){
                        newIndex = tabIndex;
                    }else{
                        if(vnode.elm.__vue__.value == this.tabsParent.value){
                            currentIndex = tabIndex;
                        }
                    }
                }
            });
            
            this.setAnimationDirection(newIndex - currentIndex);
            
            if(select){
                this.tabsParent.$emit("tabClicked", this.value, this.$el);
            }
        },
        
        setAnimationDirection(direction){
            if(direction < 0){
                // <--|
                this.$el.style.setProperty('--border-side', "left");
                this.$el.style.setProperty('--border-side-selected', "right");
            }else{
                // |-->
                this.$el.style.setProperty('--border-side', "right");
                this.$el.style.setProperty('--border-side-selected', "left");
            }
        }
    },

    mounted () {
        this.initialize();
    }
}
</script>

<style lang="scss">
.phi-tab{
    --element-height: '48px';
    --border-side: 'left';
    --border-side-selected: 'right';

    display: inline-block;
    box-sizing: border-box;
    position: relative;

    &.flexible{
        flex: 1;
        width: 0;
    }

    .phi-tab-item {
        max-height: var(--element-height);
        text-align:center;
        font-size: 14px;
        padding: 14px;
        color: #19191999;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        user-select: none;
        
        label{
            cursor: pointer;
        }
        
        .phi-icon{
            display: block;
        }

        ::-webkit-scrollbar {
            display: none;
        }
        
        &.selected{
            color: var(--phi-color-selected, #0084F7);
        }
        
        &.selected::after{
            transform: scaleX(1);
            transform-origin: bottom var(--border-side-selected, 'right');
            transition: transform 0.25s cubic-bezier(0.86, 0, 0.07, 1);
        }
    }
    
    .phi-tab-item::after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: var(--phi-color-selected, #0084F7);
        transform-origin: bottom var(--border-side, 'left');
    }

    .phi-tab-item label[badge]:before {
        background: #00BA00;

        display: inline-block;
        border-radius: 50%;
        min-width: 1em;
        min-height: 1em;
        line-height: 1em;
        position: absolute;
        right: -0.4em;
        top: 0.5em;

        color: #fff;
        content: attr(badge);
        font-size: .73em;
        padding: .3em;
        text-align: center;
    }

    .phi-tab-item label[badge^="-"]:before,
    .phi-tab-item label[badge="0"]:before,
    .phi-tab-item label[badge=""]:before {
       display: none;
       visibility: hidden;
    }
}
</style>
